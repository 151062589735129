<template>
  <v-dialog
    :value="true"
    max-width="500px"
  />
</template>

<script>
export default {
    name: 'CreateItemDialg'
}
</script>